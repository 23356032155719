import styled, { css } from "styled-components";
import { Link } from "gatsby";
import { BiTime, BiCalendarAlt } from "react-icons/bi";

export const PostsContainer = styled.div`
  max-width: 78.75rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
`;

export const PostsContent = styled.div`
  position: relative;
  top: 0px;
  height: 268px;
  gap: 1rem;

  @media screen and (min-width: 768px) {
    max-width: 339px;
  }
`;

export const Title = styled.h1`
  font-size: 1.8rem;
  border-left: 4px solid #6ed796;
  padding-left: 20px;
  display: table;
  margin-top: 0.8rem;
  @media screen and (min-width: 768px) {
    padding: 0.4rem;
  }
`;

export const Tag = styled.span`
  color: #6ed796;
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 0.05rem;
  margin-right: 0.4rem;
  margin-bottom: 1rem;
`;

export const Dot = styled.span`
  font-size: 0.7rem;
  color: #b2b2b2;
  border-radius: 50%;
`;

export const Time = styled.span`
  font-size: 0.9rem;
  color: #808080;
`;

export const TimeIcon = styled(BiTime)`
  vertical-align: middle;
  margin-bottom: 0.1rem;
`;

export const CoverImg = styled.img`
  border-radius: 5px;
  z-index: 1;
  position: relative;
  display: block;
  overflow: hidden;
  max-height: 210px;
  max-width: 339.98px;
  width: 100%;

  @media screen and (min-width: 500px) {
    min-height: 310px;
    min-width: 439.98px;
  }
  @media screen and (min-width: 768px) {
    min-height: 240px;
    min-width: 369.98px;
  }
  @media screen and (min-width: 826px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Article = styled.article`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(1, 1fr);
  grid-template-areas: none;
  grid-gap: 2rem;
  padding-top: 2rem;
  padding: 2rem;

  @media screen and (min-width: 826px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 1240px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const Calendar = styled(BiCalendarAlt)`
  vertical-align: middle;
  margin-bottom: 0.1rem;
  margin-right: 0.1rem;
`;

export const LinkArticle = styled(Link)`
  text-decoration: none;
`;

export const ContentTitle = styled.h1`
  font-size: 1.4rem;
  color: #000000;
  margin-bottom: 0.9rem;
  margin-top: 0.8rem;
  max-width: 369.98px;
  width: 100%;
  @media screen and (min-width: 768px) {
    min-width: 369.98px;
  }
`;

export const PropertiesStyles = css``;

export const Author = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  padding-top: 1rem;
  margin-bottom: 0.9rem;
`;

export const Date = styled.span`
  color: #808080;
  font-size: 0.9rem;
`;

export const Properties = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 369.98px;
  width: 100%;
  @media screen and (min-width: 768px) {
    min-width: 369.98px;
  }
`;

export const Avatar = styled.img`
  border-radius: 100%;
  width: 35px;
  height: 35px;
`;

export const Pagination = styled.div`
  padding-bottom: 2rem;
  padding-top: 0.7rem;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;

  @media screen and (min-width: 768px) {
    padding-top: 2rem;
  }
`;
export const PrevLink = styled(Link)`
  text-decoration: none;
  color: #000000;
`;
export const PrevNext = styled(Link)`
  text-decoration: none;
  color: #000000;
  &.active {
    cursor: none;
  }
`;
export const PagNumber = styled(Link)`
  color: #000000;
  text-decoration: none;

  &.active {
    color: #808080;
    pointer-events: none;
    cursor: default;
    user-select: none;
  }
`;

export const ReadMore = styled(Link)`
  color: #6ed796;
  text-decoration: none;
  &:hover {
  }
`;
