import styled, { css } from "styled-components";
import { FaFacebook } from "@react-icons/all-files/fa/FaFacebook";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";

export const FormContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 1080px;
  margin: 0 auto;
  padding-bottom: 4rem 0;

  @media screen and (min-width: 800px) {
    padding: 4rem 2rem;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and (min-width: 800px) {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1.3fr;
    grid-column: standard;
    grid-template-areas: "content form";
    justify-content: center;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  gap: 1.1rem;
  text-align: center;
  padding-right: 1rem;
  grid-area: content;
  padding: 2rem;

  @media screen and (min-width: 800px) {
    text-align: left;
    padding: 0;
  }
`;
export const FormWrapper = styled.div`
  grid-area: form;
`;

export const Title = styled.h1`
  font-size: 1.354rem;

  @media screen and (min-width: 800px) {
    font-size: 1.9rem;
  }

  @media screen and (min-width: 1000px) {
    font-size: 2.1rem;
  }
`;

export const Description = styled.p`
  color: #808080;

  @media screen and (min-width: 800px) {
    font-size: 1.1rem;
    padding-right: 2rem;
  }
`;

export const Footer = styled.footer`
  max-width: 78.75rem;
  margin: 0 auto;
  padding-top: 7.5rem;
  padding-bottom: 0;
  bottom: 0;
  clear: both;
  position: relative;
`;

export const FooterWrapper = styled.div`
  border-top: 0.0625rem solid #e6e9ea;
  padding: 2rem 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-bottom: 0.7rem;

  @media screen and (min-width: 600px) {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0 2rem;
  }
`;

export const Text = styled.div`
  grid-area: link;
  color: #808080;
  text-align: center;
  font-size: 0.875rem;
`;

export const Icons = styled.ul`
  list-style: none;
  display: flex;
  margin: 0 -0.5rem;
`;

export const Link = styled.li`
  padding: 0 0.5rem;
`;

export const IconStyle = css`
  color: #808080;
  font-size: 1.3rem;

  &:hover,
  &:focus {
    color: #6ed796;
  }
`;

export const FacebookIcon = styled(FaFacebook)`
  ${IconStyle};
`;
export const InstagramIcon = styled(FaInstagram)`
  ${IconStyle};
`;
export const LinkedinIcon = styled(FaLinkedin)`
  ${IconStyle};
`;
export const FooterLink = styled.a`
  text-decoration: none;
`;
