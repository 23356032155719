import React from "react";
import { graphql, PageProps } from "gatsby";
import Layout from "../components/layout";
import Sidebar from "../components/organisms/Sidebar/sidebar.components";
import Nav from "../components/organisms/Nav/nav.components";
import SEO from "../components/seo";
import {
  Article,
  PostsContent,
  ContentTitle,
  CoverImg,
  Date,
  LinkArticle,
  PostsContainer,
  PrevLink,
  PrevNext,
  PagNumber,
  Pagination,
  Tag,
  ReadMore,
  Properties,
  Dot,
  Time,
  TimeIcon,
  Calendar,
} from "./blogTemplate.styles";
import {
  Content,
  FacebookIcon,
  Footer,
  FooterLink,
  FooterWrapper,
  Icons,
  InstagramIcon,
  Link,
  LinkedinIcon,
  Text,
} from "../components/organisms/Container/container.styles";

const BlogTemplate: React.FC<PageProps> = ({ data, pageContext }) => {
  const [open, setOpen] = React.useState(false);
  const toggle = () => {
    setOpen(!open);
  };
  const posts = data.allMarkdownRemark.edges;
  const { currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPagePath =
    currentPage - 1 === 1 ? `/blog/` : `/blog/` + (currentPage - 1).toString();
  const nextPagePath = `/blog/` + (currentPage + 1).toString();
  const getPageNumberPath = (currentIndex) => {
    if (currentIndex === 0) {
      return `/blog/`;
    }

    return `/blog/` + (currentIndex + 1);
  };

  return (
    <Layout>
      <SEO
        title="Blog"
        description={
          "Artigos e notícias e tendências sobre logística, e-commerce e dicas de como gerenciar sua entrega last-mile e ajudar o seu negócio crescer."
        }
      />
      <Sidebar open={open} toggle={toggle} />
      <Nav toggle={toggle} open={false} />
      <PostsContainer>
        <Article>
          {posts.map(({ node, index }) => (
            <div key={index}>
              <LinkArticle to={`/blog/${node.frontmatter.url}`}>
                {node.frontmatter.cover_image.map((img, i) => (
                  <CoverImg key={i} src={img.external.url} width={100} />
                ))}
              </LinkArticle>
              <PostsContent>
                {node.frontmatter.tags.map((tag, index) => (
                  <Tag key={index}>{tag.name}</Tag>
                ))}
                <Dot>•</Dot>{" "}
                <Time>
                  <TimeIcon /> {node.timeToRead} minutos de leitura
                </Time>
                <LinkArticle to={`/blog/${node.frontmatter.url}`}>
                  <ContentTitle>{node.frontmatter.title}</ContentTitle>
                </LinkArticle>
                <Properties>
                  <Date>
                    <Calendar />
                    {node.frontmatter.publish_date.start}
                  </Date>
                  <ReadMore to={`/blog/${node.frontmatter.url}`}>
                    Leia mais →
                  </ReadMore>
                </Properties>
              </PostsContent>
            </div>
          ))}
        </Article>
      </PostsContainer>
      <Pagination>
        {isLast && (
          <PrevLink to={prevPagePath} rel="prev">
            ← Anterior
          </PrevLink>
        )}
        {Array.from({ length: numPages }, (_, i) => {
          return (
            <PagNumber
              key={i + 1}
              to={getPageNumberPath(i)}
              activeClassName="active">
              {i + 1}
            </PagNumber>
          );
        })}
        {isFirst ? (
          <PrevNext to={nextPagePath} rel="next">
            Próximo →
          </PrevNext>
        ) : (
          ""
        )}
      </Pagination>
      <Footer>
        <FooterWrapper>
          <Content>
            <Icons>
              <Link>
                <FooterLink
                  href="https://www.facebook.com/abbiamolog"
                  target="_blank">
                  <FacebookIcon />
                </FooterLink>
              </Link>
              <Link>
                <FooterLink
                  href="https://www.instagram.com/abbiamolog/"
                  target="_blank">
                  <InstagramIcon />
                </FooterLink>
              </Link>
              <Link>
                <FooterLink
                  href="https://www.linkedin.com/company/abbiamo-log/"
                  target="_blank">
                  <LinkedinIcon />
                </FooterLink>
              </Link>
            </Icons>
            <Text>©2021 Abbiamo. Todos os direitos reservados.</Text>
          </Content>
        </FooterWrapper>
      </Footer>
    </Layout>
  );
};

export default BlogTemplate;

export const query = graphql`
  query BlogTemplate($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: frontmatter___publish_date___start, order: DESC }
      filter: { frontmatter: { status: { name: { eq: "publicado" } } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          timeToRead
          frontmatter {
            url
            title
            desc
            author_name
            author_avatar {
              external {
                url
              }
            }
            cover_image {
              external {
                url
              }
            }
            tags {
              name
            }
            publish_date {
              start(formatString: "DD MMMM YYYY", locale: "pt")
            }
          }
        }
      }
    }
  }
`;
